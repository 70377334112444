$(window).on("load", function () {
    
    frappe.call("inmobiliaria.reparaciones.doctype.plantillas_de_textos_por_valores.plantillas_de_textos_por_valores.valores", {}).then(e => {
        e.message = e.message.sort((a, b) => {
            if (!a.prioridad) {
                a.prioridad = 1;
            }

            if (!b.prioridad) {
                b.prioridad = 1;
            }

            return parseInt(a.prioridad) - parseInt(b.prioridad);
        });

        let campos_trigger_global = [];

        e.message.forEach(row => {
            let campos_trigger = [];

            let func = (frm, a, b, c, ignore_others) => {
                let ejecutar = true;

                row.condiciones.forEach(c => {
                    if (frm.doc[c.campo] != c.valor) {
                        ejecutar = false;
                    }
                });

                if (ejecutar) {
                    let texto = row.valor_asignar;
                    let index1 = null;
                    let index2 = null;

                    while ((index1 = texto.indexOf("{{")) > -1 && (index2 = texto.indexOf("}}", index1)) > -1) {
                        let campo = texto.substr(index1, index2 - index1);

                        campo = campo.trim();
                        campo = campo.replace("{{", "");
                        campo = campo.replace("}}", "");
                        campo = campo.trim();

                        let parte1 = texto.substr(0, index1);
                        let parte2 = texto.substr(index2 + 2);
                        let valor = frm.doc[campo];

                        if (!valor) {
                            valor = "";
                        }

                        texto = parte1 + valor + parte2;

                        campos_trigger.push({
                            campo: campo,
                            prioridad: row.prioridad
                        });
                    }

                    let fd = frm.fields_dict[row.campo_asignar];

                    if (fd.df.set_only_once == 1 && fd.render_input == false) {

                    } else {
                        frm.doc[row.campo_asignar] = texto;
                        frm.refresh_field(row.campo_asignar);
                    }
                } else if (!ignore_others) {
                    campos_trigger_global.forEach(c => {
                        if (c.id != row.name && c.func) {
                            c.func(frm, a, b, c, true);
                        }
                    });
                }
            };

            row.condiciones.forEach(c => {
                campos_trigger.push({
                    campo: c.campo,
                    prioridad: row.prioridad
                });
            });

            let texto = row.valor_asignar;

            while (texto.indexOf("{{") > -1 && texto.indexOf("}}") > -1) {
                let index1 = texto.indexOf("{{");
                let index2 = texto.indexOf("}}");
                let campo = texto.substr(index1, index2 - index1);

                campo = campo.trim();
                campo = campo.replace("{{", "");
                campo = campo.replace("}}", "");
                campo = campo.trim();

                let parte1 = texto.substr(0, index1);
                let parte2 = texto.substr(index2 + 2);

                texto = parte1 + "" + parte2;

                campos_trigger.push({
                    campo: campo,
                    prioridad: row.prioridad
                });
            }

            campos_trigger.sort(function (a, b) {
                if (!a.prioridad) {
                    a.prioridad = 1;
                }

                if (!b.prioridad) {
                    b.prioridad = 1;
                }

                return parseInt(a.prioridad) - parseInt(b.prioridad);
            });

            campos_trigger.forEach(c => {
                let d = {};

                d[c.campo] = func;

                c['id'] = row.name;
                c['func'] = func;

                frappe.ui.form.on(row.doctype_referenciado, d);
                campos_trigger_global.push(c);
            });
        });
    });

});