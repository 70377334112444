//Coloca una lista de chequeo para adicionar automáticamente los correos relacionados en el doctype

$(window).on("load", function () {
    let timeout = null;

    $("#body_div").on("DOMNodeInserted", function () {
        if (timeout) {
            clearTimeout(timeout);
        }

        setTimeout(function () {
            let pc = $(".page-container:visible");
            let nm = pc.find(".btn-new-email, .boton-email-fix");

            nm.unbind('click', llenarCorreos);
            nm.click(llenarCorreos);
        }, 100);
    });

    frappe.llenar_correos = llenarCorreos;
});

function llenarCorreos(tipoCliente) {
    let th = $(this);
    let pc = $(".page-container:visible");
    let correos = [];

    pc.find(".frappe-control").each(function () {
        var fc = $(this);
        var fn = fc.data("fieldname");

        if (fn && (fn.indexOf("mail") > -1 || fn.indexOf("correo") > -1)) {
            var lb = fc.find('label').text()
            var iv = fc.find(".control-input input");
            var cv = fc.find(".control-value");

            if (lb && lb != "" && (iv.val() != "" || cv.text() != "")) {
                if (iv.val() && iv.val().indexOf("@") > -1 && iv.is(":visible")) {
                    let spl = iv.val().split(",");

                    spl.forEach(email => {
                        if (email.indexOf("@") > -1) {
                            correos.push({
                                'texto': lb,
                                'correo': email
                            })
                        }
                    });
                } else if (cv.text() && cv.text().indexOf("@") > -1) {
                    cv.text().split(",").forEach(email => {
                        if (email.indexOf("@") > -1) {
                            correos.push({
                                'texto': lb,
                                'correo': email
                            })
                        }
                    });
                }
            }
        }
    });

    setTimeout(function () {
        var rcp = $(".modal.in [data-fieldname=recipients]:visible");
        var fc = rcp.parents(".frappe-control");

        $(".email-fix").remove();

        if (correos.length == 0) {
            return;
        }

        if (!tipoCliente) {
            if (th.hasClass('arrendatario')) {
                tipoCliente = 'arrendatario';
            } else if (th.hasClass('propietario')) {
                tipoCliente = 'propietario';
            }
        }

        if (correos.length > 0 && tipoCliente) {
            let filtroCorreos = correos.filter(fn => {
                return fn.texto.toLowerCase().indexOf(tipoCliente) > -1;
            });

            if (filtroCorreos.length > 0) {
                correos = filtroCorreos;
            }
        }

        var append = $("<div class='frappe-control email-fix'></div>");

        append.append("<label class='control-label'>Destinatarios relacionados:</label>");

        for (var i in correos) {
            let correo = correos[i];
            let div = $("<div></div>");

            let checkbox = $(
                "<input type='checkbox' " +
                "data-correo='" + correo.correo + "' " +
                "id='check-" + correo.correo + "' />"
            );

            checkbox.change(function () {
                anadirCorreo(checkbox);
            });

            div.append(checkbox);
            div.append(
                "<label for='check-" + correo.correo + "'>" +
                correo.texto + ": " + correo.correo +
                "</label>"
            );

            append.append(div);
        }

        fc.before(append);
    }, 500);
}

function anadirCorreo(check) {
    var rcp = $(".modal.in [data-fieldname=recipients]:visible");
    var correos = rcp.val();

    if (check.is(":checked")) {
        if (correos == "") {
            correos = check.data("correo");
        } else {
            if (correos.indexOf(check.data("correo")) == -1) {
                correos += ", " + check.data("correo");
            }
        }
    } else {
        var spl = correos.split(",");
        var ncorreos = "";

        for (var i in spl) {
            var correo = spl[i];

            correo = correo.trim();

            if (correo != check.data("correo")) {
                if (ncorreos != "") {
                    ncorreos += ", ";
                }
                ncorreos += correo;
            }
        }

        correos = ncorreos;
    }

    rcp.val(correos);
}