frappe.ui.form.on('Issue', {
    after_save(frm) {
        if (frm.doc.custom_tipo_de_incidencia == "Necesito una reparación" && frm.doc.custom_contratos_de_arrendamiento) {
            frappe.model.get_value('Reparaciones', { "incidencia": frm.doc.name }, 'name', function (rp) {
                console.log("Creando reparacion");
                frappe.call({
                    method:
                        "inmobiliaria.incidencia_reparacion.crear_reparacion",
                    args: {
                        data: JSON.stringify(frm.doc),
                    },
                    async: false,
                    callback: function (rp) {
                        frm.doc.custom_solicitud_de_reparacion = rp.message.name
                        frm.refresh_field("custom_solicitud_de_reparacion")
                        frappe.db.set_value("Issue", frm.doc.name, "custom_solicitud_de_reparacion", rp.message.name);
                    }
                });
            })
        }
    },
    refresh(frm) {
        if (!frm.doc.custom_solicitud_de_reparacion) {
            frappe.model.get_value('Reparaciones', { "incidencia": frm.doc.name }, 'name', function (rp) {
                if (rp) {
                    frm.doc.custom_solicitud_de_reparacion = rp.name;
                    frm.refresh_field("custom_solicitud_de_reparacion");
                    frappe.db.set_value("Issue", frm.doc.name, "custom_solicitud_de_reparacion", rp.message.name);
                }
            })
        }
    }
});
