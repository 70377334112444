//Provee una función que se puede utilizar en cualquier doctype para mostrar los adjuntos que sean imágenes
//como una galería en una ventana emergente

//Funcion para abrir en una Modal todas las imagenes que estan en el Plugin de Registro de llamadas
// en el DocType Centro de Servicios
$(document).on("click", ".datatable img", function(){
    var src = $(this).attr("src");

    $("#modal-search").attr("style","display:none;");

    $(".modal-dialog").attr("style","width:900px;");
    $(".modal-content").html("<img src='"+ src +"'>");

    $("#search-modal").modal("show");
    
});

frappe.galeria_imagenes = function (doctype, name, href) {
    frappe
        .call('inmobiliaria.rpc.files', {
            doctype: doctype,
            name: name
        })
        .then((registros) => {
            let images = [];

            for (var i in registros.message) {
                let row = registros.message[i];
                let fn = row.file_name.toLowerCase();

                if (
                    fn.indexOf('.jpg') > 0 ||
                    fn.indexOf('.jpeg') > 0 ||
                    fn.indexOf('.png') > 0 ||
                    fn.indexOf('.gif') > 0
                ) {
                    images.push(row);
                }
            }

            if (images.length > 0) {
                let modal = $(
                    `<div class="modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document" style="width: 90%;">
                    <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title">Galería de Imágenes para ${doctype} - ${name} </h4>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                    </div>
                    </div>
                </div>
                </div>`
                );

                let div = $(`<div></div>`);
                let modalBody = modal.find('.modal-body');

                for (var i in images) {
                    let row = images[i];
                    let fn = row.file_name.replace(/_/g, '-');

                    div.append(
                        `<a href="${row.file_url}">
                            <img src="${row.file_url}" style='max-width: 100px;' />
                        </a>`
                    );
                }

                modalBody.append(div);

                $('body').append(modal);

                modal.on('hidden.bs.modal', function () {
                    modal.remove();
                });

                div.lightGallery({
                    thumbnail: true
                });

                let done = false;

                console.log(href);

                if (href) {
                    div.find('a').each((index, element) => {
                        console.log(href, $(element).prop('href'));

                        if ($(element).prop('href') == href) {
                            $(element).click();
                            done = true;

                            console.log(
                                'OPENING',
                                href,
                                $(element).prop('href')
                            );
                        }
                    });
                }

                if (!done) {
                    $(div.find('a').get(0)).click();
                }
            } else {
                // with options
                frappe.msgprint({
                    title: __('Notification'),
                    indicator: 'red',
                    message: __(
                        'Este documento no contiene imágenes para hacer una galería'
                    )
                });
            }
        });
};

$(document).ready(function () {
    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    frappe.ui.form.handlers_refresh.push(function (frm, doctype, name) {
        if (frm.doc.__islocal) {
            return;
        }

        let add = false;

        frm.$wrapper
            .find('.form-attachments .attachment-row span')
            .each((index, element) => {
                let fn = $(element).text();

                if (fn) {
                    let ext = ['.jpeg', '.jpg', '.png', '.gif', '.webp'];

                    ext.forEach((e) => {
                        if (fn && fn.toLowerCase().indexOf(e) > -1) {
                            add = true;
                        }
                    });
                }
            });

        if (add) {
            $('.file-thumbnail').click(function (evt) {
                let url = $(this).prop('href');
                let href = $(this).prop('href');

                if (url) {
                    url = url.toLowerCase();
                } else {
                    url = '';
                }

                if (
                    url.indexOf('.jpg') > 0 ||
                    url.indexOf('.jpeg') > 0 ||
                    url.indexOf('.png') > 0 ||
                    url.indexOf('.gif') > 0
                ) {
                    evt.preventDefault();
                    frappe.galeria_imagenes(frm.doctype, frm.doc.name, href);
                }
            });

            $('.form-attachments .attachment-row').each(function () {
                let a = $(this).find('a');

                if (a.length > 2) {
                    let al = $(a.get(2));

                    al.unbind('click');
                    al.click(function (evt) {
                        let url = $(this).prop('href');
                        let href = $(this).prop('href');

                        if (!url) {
                            url = '';
                        }

                        url = url.toLowerCase();

                        if (
                            url.indexOf('.jpg') > 0 ||
                            url.indexOf('.jpeg') > 0 ||
                            url.indexOf('.png') > 0 ||
                            url.indexOf('.gif') > 0
                        ) {
                            evt.preventDefault();
                            frappe.galeria_imagenes(
                                frm.doctype,
                                frm.doc.name,
                                href
                            );
                        }
                    });
                }
            });

            frm.add_custom_button(
                'Galería',
                function () {
                    frappe.galeria_imagenes(frm.doctype, frm.doc.name);
                },
                null,
                'warning'
            );
        }
    });
});
