$(document).ready(function () {
    frappe.ui.form.on("Opportunity", {
        tipo_de_cliente(frm) {
            if (frm.doc.tipo_de_cliente == "Propietario") {
                cotizador(frm);
            } else {
                frm.page.remove_inner_button(__('Cotizador'));
            }
        },

        refresh(frm) {
            if (frm.doc.tipo_de_cliente == "Propietario") {
                cotizador(frm);
            }
            $('a:contains("Cotización")').hide();
            $(".form-dashboard").hide();
        }
    });
});


function cotizador(frm) {
    frm.add_custom_button(__('Cotizador'), function () {
        var dialog = new frappe.ui.Dialog({
            title: "Cotización de servicios inmobiliarios UNISA SA",
            fields: [
                {
                    fieldname: "nombre_cliente",
                    fieldtype: "Data",
                    label: "Nombre del cliente",
                    reqd: 1,
                    read_only: 1,
                    default: frm.doc.customer_name
                },
                {
                    fieldname: "direccion_inmueble",
                    fieldtype: "Data",
                    label: "Dirección del inmueble.",
                    reqd: 1
                },
                {
                    fieldname: "valor_canon",
                    fieldtype: "Currency",
                    label: "Valor del canon (IVA incluido).",
                    reqd: 1,
                    onchange() {
                        if (this.value) {
                            calcular_valores(dialog)
                        }
                    }
                },
                {
                    fieldname: "tarifa_unisa",
                    fieldtype: "Select",
                    label: "Tarifa UNISA.",
                    options: ["", "8%", "9%", "10%"],
                    reqd: 1,
                    onchange() {
                        if (this.value && dialog.fields_dict.valor_canon.value) {
                            dialog.fields_dict.total_a_consignar.value = ""
                            dialog.fields_dict.total_a_consignar.refresh()

                            dialog.fields_dict.total_a_descontar.value = ""
                            dialog.fields_dict.total_a_descontar.refresh()

                            calcular_valores(dialog)
                        }
                    }
                },

                {
                    fieldname: "administracion_unidad",
                    fieldtype: "Currency",
                    label: "Administración de la copropiedad.",
                    onchange() {
                        calcular_valores(dialog)
                    }
                },
                {
                    fieldname: "administracion_unisa",
                    fieldtype: "Currency",
                    label: "Administración Unisa.",
                    read_only: 1
                },
                {
                    fieldname: "seguro",
                    fieldtype: "Currency",
                    label: "Seguro.",
                    read_only: 1
                },
                {
                    fieldname: "iva",
                    fieldtype: "Currency",
                    label: "IVA",
                    read_only: 1
                },
                {
                    fieldname: "gastos_bancarios",
                    fieldtype: "Currency",
                    label: "Gastos bancarios",
                    read_only: 1
                },
                {
                    fieldname: "tarifa_minima",
                    fieldtype: "Currency",
                    label: "Tarifa mínima",
                    read_only: 1,
                    hidden: 1
                },

                {
                    fieldname: "total_a_descontar",
                    fieldtype: "Currency",
                    label: "Total a descontar",
                    read_only: 1
                },
                {
                    fieldname: "total_a_consignar",
                    fieldtype: "Currency",
                    label: "Total a consignar",
                    read_only: 1
                }

            ],
            primary_action_label: "Guardar",
            primary_action: function () {
                var datos = dialog.get_values()
                datos['oportunidad'] = frm.doc.name
                datos['email'] = frm.doc.contact_email

                frappe.call({
                    method:
                        "inmobiliaria.cotizador_oportunidad.enviar_email",
                    args: {
                        datos: JSON.stringify([datos]),
                    },
                    async: true,
                    callback: function (rp) {
                        if (rp.message) {
                            frm.save()
                            dialog.hide()
                            frappe.msgprint(rp.message.respuesta)
                        }
                    }
                })

            }
        });
        dialog.show();
    }).addClass("btn-warning");
}


function calcular_valores(valores) {
    var tarifa = 0;
    switch (valores.fields_dict.tarifa_unisa.value) {
        case "8%":
            tarifa = 0.08;
            break;
        case "9%":
            tarifa = 0.09;
            break;
        case "10%":
            tarifa = 0.10;
            break;
        default:
            break;
    }


    valores.fields_dict.administracion_unisa.value = valores.fields_dict.valor_canon.value * tarifa
    valores.fields_dict.administracion_unisa.refresh()

    valores.fields_dict.iva.value = valores.fields_dict.administracion_unisa.value * 0.19
    valores.fields_dict.iva.refresh()

    valores.fields_dict.seguro.value = valores.fields_dict.valor_canon.value * 0.02
    valores.fields_dict.seguro.refresh()

    frappe.call({
        method: "frappe.client.get",
        args: {
            doctype: "Configuracion Cotizador",
        },
        callback(r) {
            if (r.message) {
                valores.fields_dict.gastos_bancarios.value = r.message.gastos_bancarios
                valores.fields_dict.gastos_bancarios.refresh()

                valores.fields_dict.tarifa_minima.value = r.message.tarifa_minima
                valores.fields_dict.tarifa_minima.refresh()
            }
        }
    });


    var canon = valores.fields_dict.valor_canon.value;
    var seguro = valores.fields_dict.seguro.value;
    var iva = valores.fields_dict.iva.value;
    var administracion_unisa = valores.fields_dict.administracion_unisa.value;
    var gastos_bancarios = valores.fields_dict.gastos_bancarios.value;
    var tarifa_minima = valores.fields_dict.tarifa_minima.value;
    var administracion_unidad = valores.fields_dict.administracion_unidad.value;

    if (administracion_unisa < tarifa_minima) {
        valores.fields_dict.administracion_unisa.value = tarifa_minima
    }
    valores.fields_dict.administracion_unisa.refresh()
    valores.fields_dict.iva.value = valores.fields_dict.administracion_unisa.value * 0.19
    valores.fields_dict.iva.refresh()

    iva = valores.fields_dict.iva.value;
    administracion_unisa = valores.fields_dict.administracion_unisa.value;
    administracion_unidad = valores.fields_dict.administracion_unidad.value;

    var total_a_descontar = administracion_unisa + seguro + iva + gastos_bancarios + administracion_unidad;
    valores.fields_dict.total_a_descontar.value = total_a_descontar || ""
    valores.fields_dict.total_a_descontar.refresh()

    valores.fields_dict.total_a_consignar.value = (canon - total_a_descontar) || ""
    valores.fields_dict.total_a_consignar.refresh()
}