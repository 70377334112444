$(document).ready(function () {
    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    frappe.ui.form.handlers_refresh.push(function (frm, doctype, name) {
        let wrapper = $(frm.$wrapper);
        let fp = wrapper.find(".form-page");

        fp.find(".plugin-eventos").remove();
        
        if (frm.doc.__islocal) {
            return;
        }

        if (frm.fields_dict['contrato_arrendamiento'] == null) {
            return null;
        }

        frm.add_custom_button("Crear Evento", function () {
            if (!frm.doc.contrato_arrendamiento) {
                frappe.msgprint("Error, este documento no tiene asignado un contrato de arrrendamiento");
                return;
            }

            let contrato = null;

            frappe.call({
                method: 'frappe.client.get',
                args: {
                    doctype: 'Contratos de Arrendamiento',
                    name: frm.doc.contrato_arrendamiento
                },
                async: false,
                callback: function (rp) {
                    contrato = rp.message;
                }
            });

            if (!contrato) {
                frappe.msgprint("Error, este contrato no fue encontrado");
                return;
            }

            var d = new frappe.ui.Dialog({
                'title': 'Crear Evento',
                'fields': [
                    {'fieldname': 'categoria', 'fieldtype': 'Select', 'label': 'Categoría de Evento', 'options': ['Event', 'Meeting', 'Call', 'Sent/Received Email', 'Other']},
                    {'fieldname': 'tipo', 'fieldtype': 'Select', 'label': 'Tipo de Evento', 'options': ['Private', 'Public']},
                    {'fieldname': 'tipo_cliente', 'fieldtype': 'Select', 'label': 'Tipo de Cliente', 'options': ['Cliente Arrendatario', 'Cliente Propietario']},
                    {'fieldname': 'fecha_hora', 'fieldtype': 'Datetime', 'label': 'Fecha y Hora'},
                    {'fieldname': 'enviar_correo', 'fieldtype': 'Check', 'label': 'Enviar Correo'},
                    {'fieldname': 'asunto', 'fieldtype': 'Data', 'label': 'Asunto'},
                    {'fieldname': 'descripcion', 'fieldtype': 'Text Editor', 'label': 'Descripción'},
                ],
                primary_action: function() {
                    let dict = d.get_values();

                    if (!dict['categoria'] || !dict['tipo'] || !dict['tipo_cliente'] || !dict['fecha_hora'] || !dict['asunto'] || !dict['descripcion']) {
                        frappe.show_alert("Por favor, llene todos los campos");
                        return;
                    }

                    d.hide();

                    let descripcion = `
                        <b>ID Caso:</b> <a href='#Form/${frm.doctype}/${frm.doc.name}'>${frm.doctype} ${frm.doc.name}</a><br />
                        <b>ID Simi:</b> ${contrato.id_simi}<br />
                        <br />`;
                    let destinatarios = "";

                    if (dict['tipo_cliente'] == 'Cliente Arrendatario') {
                        descripcion += `
                            <b>Cliente Arrendatario:</b> ${contrato.nombre_cliente_arrendatario}<br />
                            <b>Teléfono:</b> ${contrato.telefono_cliente_arrendatario || ''}<br />
                            <b>Celular:</b> ${contrato.celular_cliente_arrendatario || ''}<br />
                            <b>Correo:</b> ${contrato.correo_cliente_arrendatario || ''}<br />
                            <br />`;
                        
                        destinatarios = contrato.correo_cliente_arrendatario;
                    } else {
                        descripcion += `
                            <b>Cliente Propietario:</b> ${contrato.nombre_cliente_propietario}<br />
                            <b>Teléfono:</b> ${contrato.telefono_cliente_propietario || ''}<br />
                            <b>Celular:</b> ${contrato.celular_cliente_propietario || ''}<br />
                            <b>Correo:</b> ${contrato.correo_cliente_propietario || ''}<br />
                            <br />`;

                        destinatarios = contrato.correo_cliente_propietario;
                    }

                    if (!destinatarios) {
                        frappe.msgprint("Este cliente no tiene correos asignados");
                    }

                    let event_participants = [];

                    destinatarios.split(",").forEach((correo, index) => {
                        correo = correo.trim();

                        if (!correo) {
                            return;
                        }

                        let contact_name = null;

                        frappe.call({
                            method: 'frappe.client.get_list',
                            async: false,
                            args: {
                                doctype: 'Contact',
                                filters: {
                                    email_id: correo
                                },
                                fields: '*'
                            }
                        }).then(rp => {
                            if (rp.message.length > 0) {
                                contact_name = rp.message[0].name;
                            }
                        });

                        if (!contact_name) {
                            frappe.call({
                                method: 'frappe.client.insert',
                                args: {
                                    doc: {
                                        doctype: 'Contact',
                                        first_name: correo,
                                        email_id: correo,
                                        email_ids: [{
                                            email_id: correo,
                                            is_primary: 1,
                                            idx: 0,
                                            __checked: 1
                                        }]
                                    }
                                },
                                async: false
                            }).then(rp => {
                                if (rp.message) {
                                    contact_name = rp.message.name;
                                }
                            });
                        }

                        event_participants.push({
                            reference_doctype: 'Contact',
                            reference_docname: contact_name,
                            idx: index,
                              __checked: 1
                        });
                    });

                    frappe.call({
                        "method": "frappe.client.insert",
                        args: {
                            doc: {
                                doctype: 'Event',
                                send_reminder: 1,
                                event_category: dict['categoria'],
                                event_type: dict['tipo'],
                                status: 'Open',
                                subject: dict['asunto'],
                                starts_on: dict['fecha_hora'],
                                description: descripcion + dict['descripcion'],
                                event_participants: event_participants,
                                doctype_caso: frm.doc.doctype,
                                id_caso: frm.doc.name,
                                id_simi: contrato.id_simi
                            }
                        },
                        callback: function () {
                            frappe.msgprint("Evento creado correctamente");
                            frm.refresh();

                            if (dict['enviar_correo'] == 1) {
                                frappe.call({
                                    method: 'inmobiliaria.rpc.recordatorio_evento',
                                    args: {
                                        destinatarios: destinatarios,
                                        asunto: 'Nuevo evento creado en el sistema: ' + dict['asunto'] + ', Para la fecha: ' + dict['fecha_hora'],
                                        mensaje: descripcion + dict['descripcion']
                                    },
                                    callback: function (rp) {
                                        if (rp.message.ok) {
                                            frappe.msgprint("Correo enviado correctamente");
                                        } else {
                                            frappe.msgprint("Error al enviar el correo: " + rp.message.error);
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            });

            d.show();
        }, null, "info");
        
        frappe.call({
            method: "inmobiliaria.rpc.eventos_doctype",
            args: {
                doctype: frm.doc.doctype,
                name: frm.doc.name
            },
            callback: (r) => {
                /*r.message.forEach(i => {
                    pc.append(`<option value="${i.name}">${i.subject}</option>`);
                });*/
            },
            async: false
        }).then(ret => {
            if (ret.message.length > 0) {
                let section = $(`
                <div class="row form-section visible-section shaded-section plugin-eventos">
                    <div class="col-sm-12">
                        <h6 class="form-section-heading uppercase">EVENTOS ASOCIADOS AL CASO</h6>
                    </div>
                    <div class="section-body">
                        <div class="form-column col-sm-12 col-xs-12">
                            <form>
                                <table class='table table-striped table-condensed'>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Asunto</th>
                                    <th>Comienza En</th>
                                    <th>Categoría de Evento</th>
                                    <th>Tipo de Evento</th>
                                    <th class='none'>Descripción</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
                `);

                fp.prepend(section);

                let table = section.find("table");
                let tbody = section.find("tbody");

                tbody.html("");

                ret.message.forEach(i => {
                    let tr = $(`
                    <tr>
                        <td><a href='#Form/Event/${i.name}'>${i.name}</a></td>
                        <td>${i.subject}</td>
                        <td>${i.starts_on}</td>
                        <td>${__(i.event_category)}</td>
                        <td>${__(i.event_type)}</td>
                        <td class='text-ellipsis'>${i.description}</td>
                    </tr>
                    `);
                    
                    tbody.append(tr);
                });

                let datatable = table.DataTable({
                    "language": {
                        "sProcessing":     "Procesando...",
                        "sLengthMenu":     "Mostrar _MENU_ registros",
                        "sZeroRecords":    "No se encontraron resultados",
                        "sEmptyTable":     "Ningún dato disponible en esta tabla =(",
                        "sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
                        "sInfoPostFix":    "",
                        "sSearch":         "Buscar:",
                        "sUrl":            "",
                        "sInfoThousands":  ",",
                        "sLoadingRecords": "Cargando...",
                        "oPaginate": {
                            "sFirst":    "Primero",
                            "sLast":     "Último",
                            "sNext":     "Siguiente",
                            "sPrevious": "Anterior"
                        },
                        "oAria": {
                            "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
                            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                        },
                        "buttons": {
                            "copy": "Copiar",
                            "colvis": "Visibilidad"
                        }
                    },
                    "responsive": true
                });
            }
        });

    });
});