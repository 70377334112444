frappe.ui.form.on('Customer', {
    lista_de_inmuebles(frm) {
        let dialog = new frappe.ui.Dialog({
            size: "large",
            title: "Lisa de inmuebles",
            fields: [
                { fieldname: "tabla", label: "", fieldtype: "HTML" }
            ],

            primary_action_label: "Actualizar",
            primary_action: function () {
                let values = dialog.get_values();
                let wrapper = dialog.fields_dict["tabla"].$wrapper;
                let table = $(`
                        <table  class="table table-striped table-condensed">
                            <thead>
                                <tr>
                                <th style="text-align:center">Dirección</th>
                                <th style="text-align:center">Ciudad</th> 
                                <th style="text-align:center">Acción</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    `);

                wrapper.html(table);

                frappe.db
                    .get_list("Inmuebles", {
                        fields: "*",
                        filters: {
                            cliente_propietario: frm.doc.customer_name,
                        }
                    })
                    .then((records) => {
                        if (records) {
                            table.find("tbody").html("");

                            records.forEach((record) => {
                                let tr = $(`
                                    <tr>
                                    <td style="text-align:center">${record.direccion}</td>
                                    <td style="text-align:center">${record.ciudad}</td> 
                                    <td style="text-align:center"><a  href="desk#Form/Inmuebles/${record.name}" target="_blank">Ver detalles</a></td>
                                    </tr>
                                `);
                                table.find("tbody").append(tr);
                            });
                        }
                    });
            }
        });
        dialog.show();
    },
})

