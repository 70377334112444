$(window).on("load", function () {
    let bk = frappe.request.cleanup;
    let tm = null;

    frappe.request.handlers = [];

    frappe.request.cleanup = function (a, b) {
        bk(a, b);

        if (frappe.request.handlers) {
            clearTimeout(tm);
            tm = setTimeout(function () {
                frappe.request.handlers.forEach(i => {
                    i(a, b);
                });
            }, 500);
        }
    };
});