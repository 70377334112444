frappe.ui.form.on('Issue', {
    custom_contratos_donde_es_arrendatario(frm) {
        filtrar_contratos(frm);
    },
    customer(frm) {
        filtrar_contratos(frm)
        frm.set_df_property('custom_contratos_de_arrendamiento', 'hidden', 0);
    },
    refresh(frm) {
        frm.set_df_property('custom_contratos_de_arrendamiento', 'hidden', 1);

        if (frm.doc.customer) {
            frm.set_df_property('custom_contratos_de_arrendamiento', 'hidden', 0);
            filtrar_contratos(frm)
            frm.set_value('custom_contratos_vigentes', 1)
        }
    },
    custom_contratos_vigentes(frm) {
        if (frm.doc.customer !== undefined || frm.doc.customer !== null) {
            filtrar_contratos(frm)
        }
    },
    custom_contratos_de_arrendamiento(frm) {
        let contrato_seleccionado = frm.doc.custom_contratos_de_arrendamiento.split(" - ")
        frm.set_value('custom_contrato_seleccionado', contrato_seleccionado[0])
        frm.set_value('custom_id_simi', contrato_seleccionado[2])
    }
});

function filtrar_contratos(frm) {
    var contratos = [""];
    var filtro;
    var check_contratos_vigentes = frm.doc.custom_contratos_vigentes;

    frm.set_df_property('custom_contratos_de_arrendamiento', 'options', ['Cargando contratos.........!']);
    frm.refresh_field("custom_contratos_de_arrendamiento")

    for (var i = 0; i < 2; i++) {
        filtro = { cliente_propietario: frm.doc.customer };
        if (i == 1) {
            filtro = { cliente_arrendatario: frm.doc.customer };
        }
        if (check_contratos_vigentes == 1) {
            filtro = { cliente_propietario: frm.doc.customer, estado_contrato: 'Vigente' };
            if (i == 1) {
                filtro = { cliente_arrendatario: frm.doc.customer, estado_contrato: 'Vigente' };
            }
        }
        frappe.call({
            method: 'frappe.client.get_list',
            args: {
                doctype: 'Contratos de Arrendamiento',
                fields: [
                    'name',
                    'direccion',
                    'cliente_propietario',
                    'cliente_arrendatario',
                    'correo_cliente_arrendatario',
                    'correo_cliente_propietario',
                    'estado_contrato',
                    'id_simi'
                ],
                filters: filtro
            },
            async: false,
            callback: function (rp) {
                if (rp) {
                    for (let i = 0; i < rp.message.length; i++) {
                        contratos.push(rp.message[i].name + ' - ' + rp.message[i].direccion + ' - ' + rp.message[i].id_simi);
                    }
                    frm.set_df_property('custom_contratos_de_arrendamiento', 'options', contratos)
                    frm.refresh_field("custom_contratos_de_arrendamiento")
                }
            }
        });
    }
}
