// AÑADE UNA URL A LA LISTA DE INFORMES EN EL DOCTYPE CENTRO DE SERVICIOS

$(window).on("load", function () {
    frappe.onRoute('page-List\\/Centro\\ de\\ Servicio\\/List', function (csl) {
        let ul = csl.find(".layout-side-section .reports-dropdown");

        frappe.call("inmobiliaria.rpc.reporte_registro_actividad_permitido", {}).then(result => {
            if (result.message) {
                ul.append(
                    "<li><a target='_blank' href='/api/method/inmobiliaria.rpc.reporte_registro_actividad'>Reporte Registro de Actividad</a></li>"
                );
            }
        });
    });
});