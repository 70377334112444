frappe.listview_settings["Email Group"] = {
    agregar_boton_personalizado(name, type, action, wrapper_class = ".page-actions") {
        const button = document.createElement("button");
        button.classList.add("btn", "btn-" + type, "btn-sm", "ml-2");
        button.innerHTML = name;
        button.onclick = action;
        document.querySelector(wrapper_class).prepend(button);
    },
    onload(listview) {
        this.agregar_boton_personalizado("Actualizar grupos de correo", "default", function () {
            frappe.call({
                method: 'inmobiliaria.grupos_de_correo_clientes.resolver_grupos_de_correo',
                async: false,
                callback: function (rp) {
                    if (rp.message) {
                        frappe.msgprint(`Se ha actualizado la lista de correos de clientes con ${rp.message.numero_registros} registros.`);
                        listview.refresh();
                    }
                }
            });
        })
    },
}