
frappe.ui.form.on('Issue', {
    custom_tipo_de_incidencia(frm) {
        // if (frm.doc.select_issue_type) {
        //     tipo_de_solicitud_alt(frm)
        // }
        switch (frm.doc.custom_tipo_de_incidencia) {
            case "Solicitud de servicios":
                frm.set_df_property(
                    'custom_tipo_de_solicitud',
                    'options',
                    [
                        'Solicitud de reubicación',
                        'Solicitud de servicio comercial',
                        'Otra',
                    ]
                );
                break;

            case "Solicitud de reparación":
                frm.set_df_property(
                    'custom_tipo_de_solicitud',
                    'options',
                    [
                        'Solicitud de garantía de la reparación',
                        'Solicitud de reintegro por reparación',
                        'Solicitud de reparación',
                        'Otra',
                    ]
                );
                break;

            case "Solicitudes relacionadas con pagos":
                frm.set_df_property(
                    'custom_tipo_de_solicitud',
                    'options',
                    [
                        'Solicitud de pago de impuestos',
                        'Solicitud relacionada con pagos de administración',
                        'Solicitud relacionada con pagos de arrendatarios',
                        'Solicitud relacionada con pagos de propietarios',
                        'Solicitudes de acuerdos y ajustes',
                        'Otra',
                    ]
                );
                break;

            case "Solicitud de Documentos":
                frm.set_df_property(
                    'custom_tipo_de_solicitud',
                    'options',
                    [
                        'Necesito mi cupón',
                        'Necesito mi estado de cuenta',
                        'Necesito copia de mi contrato',
                        'Necesito una certificación arrendatario',
                        'Necesito una certificación propietario',
                        'Necesito copia del inventario',
                        'Otra',
                    ]
                );
                break;

            case "Solicitudes relacionadas con mi contrato":
                frm.set_df_property(
                    'custom_tipo_de_solicitud',
                    'options',
                    [
                        'Procesos jurídicos',
                        'Información relacionada con procesos de restitución',
                        'Otra',
                    ]
                );
                break;

            case "Quejas y Reclamos":
                frm.set_df_property(
                    'custom_tipo_de_solicitud',
                    'options',
                    [
                        'Inconformidad con el servicio',
                        'Fallas con plataformas digitales',
                        'Incumplimiento de las condiciones pactadas',
                        'Atención del personal',
                        'Garantía por reparaciones',
                        'Reposición de muebles y equipos',
                    ]
                );
                break;

            case "Solicitudes Relacionadas con la Desocupación":
                frm.set_df_property(
                    'custom_tipo_de_solicitud',
                    'options',
                    [
                        'Necesito desocupar',
                        'Solicito autorización para la mudanza',
                        'Necesito paz y salvo con Unisa',
                        'Proceso de restitución',
                        'Otra',
                    ]
                );
                break;

            default:
                break;
        }
    },
})
