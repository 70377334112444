$(window).on("load", function () {
    let reporte = function () {

        let modal = $(`
    <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
        <!-- Modal content-->
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Reporte No Conforme Interno</h4>
        </div>
        <div class="modal-body">
            <div class='chart' style='width: 100%; height: 300px;'></div>
            <table class='table'>
            <thead>
            <tr>
            <th>ID</th>
            <th>Estado</th>
            <th>Prioridad</th>
            <th>Área</th>
            <th>Tipología</th>
            <th>Creación</th>
            </tr>
            </thead>
            <tbody>

            </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
        </div>
        </div>
    </div>
    </div>
        `);

        $("body").append(modal);

        modal.modal();

        frappe.call("inmobiliaria.rpc.no_conforme_interno", {}).then(recs => {
            var data_global = [[
                'Área', 'Registros'
            ]];

            recs.message.count.forEach(i => {
                data_global.push([
                    i[0],
                    i[1]
                ]);
            });

            let body = modal.find(".chart").get(0);
        
            google.charts.load('current', {'packages':['bar']});
            google.charts.setOnLoadCallback(drawStuff);

            function drawStuff() {
                var data = new google.visualization.arrayToDataTable(data_global);

                var options = {
                    width: 800,
                    legend: { position: 'none' },
                    chart: {
                    title: null,
                    subtitle: null },
                    axes: {
                    x: {
                        0: { side: 'top', label: 'Reporte No Conforme Interno'} // Top x-axis.
                    }
                    },
                    bar: { groupWidth: "90%" }
                };

                var chart = new google.charts.Bar(body);
                // Convert the Classic options to Material options.
                chart.draw(data, google.charts.Bar.convertOptions(options));
            };

            let tbody = modal.find("tbody");

            recs.message.tabla.forEach(i => {
                let tr = $("<tr></tr>");

                tr.append(`<td>${i.name}</td>`);
                tr.append(`<td>${i.estado}</td>`);
                tr.append(`<td>${i.prioridad}</td>`);
                tr.append(`<td>${i.area}</td>`);
                tr.append(`<td>${i.tipologia}</td>`);
                tr.append(`<td>${i.creation}</td>`);

                tbody.append(tr);
            });
        });
    };

    frappe.onRoute('page-List\\/No\\ Conforme\\ Interno\\/List', function (csl) {
        let ul = csl.find(".layout-side-section .reports-dropdown");
        let li = $("<li><a href='javascript:;'>Reporte No Conforme Interno</a></li>");

        li.click(reporte);
        ul.append(li);
    });
});
