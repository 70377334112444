$(window).ready(function () {
    let generar_reporte = function () {

        var d = new frappe.ui.Dialog({
            'title': 'Reporte SLA',
            'fields': [{
                    'fieldname': 'doctype',
                    'fieldtype': 'Link',
                    'options': 'DocType',
                    'label': 'DocType'
                },
                {
                    'fieldname': 'fecha_desde',
                    'fieldtype': 'Date',
                    'label': 'Fecha Desde'
                },
                {
                    'fieldname': 'fecha_hasta',
                    'fieldtype': 'Date',
                    'label': 'Fecha Hasta'
                },
            ],
            primary_action: function () {
                let dict = d.get_values();

                if (!dict['doctype'] || !dict['fecha_desde'] || !dict['fecha_hasta']) {
                    frappe.show_alert("Por favor, llene todos los campos");
                    return;
                }

                d.hide();

                let form = $(`
                    <form target='_blank' action='/api/method/inmobiliaria.rpc.reporte_sla'>
                        <input type='hidden' name='doctype' value='' />
                        <input type='hidden' name='fecha_desde' value='' />
                        <input type='hidden' name='fecha_hasta' value='' />
                    </form>
                `);

                form.find("[name=doctype]").val(dict['doctype']);
                form.find("[name=fecha_desde]").val(dict['fecha_desde']);
                form.find("[name=fecha_hasta]").val(dict['fecha_hasta']);

                $("body").append(form);

                form.submit();

                setTimeout(function () {
                    form.remove();
                }, 500);
            }
        });

        d.show();
    };

    $(document).on("page-change", function () {
        let page = $("#page-modules");

        if (page.length == 0) {
            return;
        }

        if (document.location.href.endsWith('#modules/Desk')) {
            let anadir = function () {
                let sbs = page.find(".sections-container .section-box");

                if (sbs.length == 0) {
                    setTimeout(anadir, 200);
                    return;
                }

                let sb = $(sbs.get(0));

                if (sb.find(".reportes-sla-box").length == 0) {
                    let div = $(`
                        <div style='margin-bottom: 10px;' data-v-32b346d7="" data-v-5a595d08="" class="link-item flush-top small reportes-sla" type="page" description="Reportes SLA">
                            <a data-v-32b346d7="" href="javascript:;" class="link-content" style='margin-left: 17px;'>
                                Reportes SLA
                            </a>
                        </div>`);

                    div.click(() => {
                        generar_reporte();
                    });

                    sb.append(div);
                }
            };

            anadir();
        }
    });

    $(document).trigger('page-change');

    let doit = function () {
        let div = $('div[data-module-name="Desk"]');
        let ul = div.find("ul.list-reset");

        if (ul.find(".reportes-sla").length > 0) {
            return;
        }

        let li = $(`<li class='reportes-sla'><a href='javascript:;' class='list-item' draggable='false'>Reportes SLA</a></li>`);

        ul.find("li.border-top").before(li);

        li.click(() => {
            generar_reporte();
        });

        setTimeout(doit, 1000);
    };

    doit();
});