$(window).on("load", function () {
    let timeout = null;

    let func = function () {
        clearTimeout(timeout);

        $(".timeline-item[data-communication-type='Comment']:visible .frappe-timestamp, " +
            ".timeline-item[data-communication-type='Communication']:visible .frappe-timestamp").each(function () {
            let t = $(this);
            let title = t.prop("title");
            let split = title.split(' ');
            let d = split[0];
            let dp = d.split("-");
            let nd = new Date(dp[2] + "-" + dp[1] + "-" + dp[0] + " " + split[1]);

            if (dp[0].length == 4) {
                nd = new Date(title);
            }

            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

            t.html(nd.toLocaleDateString("es-ES", options));
        });

        timeout = setTimeout(func, 5000);
    };

    func();

    $(window).on('hashchange', function () {
        setTimeout(func, 2000);
    });
});