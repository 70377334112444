//Muestra una ventana emergente notificando los casos que tengan prioridad alta,
//asignados al usuario actual y que estén en estado Abierto

$(window).on("load", function () {

    if ($("#page-login").length > 0) {
        sessionStorage.clear();
    } else if (frappe.session.user != 'Guest') {
        frappe.call("inmobiliaria.rpc.prioridades_centro_servicio", {}).then(registros => {
            let mensaje = "";

            for (var i in registros.message) {
                var row = registros.message[i];

                if (sessionStorage.getItem(row.name)) {
                    continue;
                }

                sessionStorage.setItem(row.name, "true");

                mensaje += "<a href='/desk#Form/Centro%20de%20Servicio/" + row.name + "'>Centro de Servicio " + row.name + "</a><br />";
            }

            if (mensaje) {
                frappe.msgprint({
                    title: 'Casos en Centro de Servicio',
                    indicador: 'red',
                    message: "Tiene pendiente revisar los siguientes casos que están marcados como prioridad Alta:<br />" +
                    "<br />" + mensaje
                });
            }

        });

        frappe.boton_comentado = function () {
            if ($("#dropdown-notification .comentado-en").length == 0) {
                frappe.call("inmobiliaria.rpc.casos_comentados", {}).then(registros => {
                    let count = registros.message.length;
                    let li = $("<li><a class='badge-hover comentado-en'>Mencionado En<span class='badge pull-right'>" + count + "</span></a></li>");
            
                    $("#dropdown-notification").append(li);
    
                    li.click(function () {
                        frappe.casos_comentados();
                    });
                });
            }

            setTimeout(frappe.boton_comentado, 30000);
        };

        /*setTimeout(function () {
            frappe.boton_comentado();
        }, 1000);*/
    
        frappe.casos_comentados = function () {
            frappe.call("inmobiliaria.rpc.casos_comentados", {}).then(registros => {
                let mensaje = "";
    
                for (let i in registros.message) {
                    let row = registros.message[i];
                    let key = row.doctype + row.name;
    
                    mensaje += "<a href='/desk#Form/" + row.doctype + "/" + row.name + "'>" + row.doctype + " " + row.name + "</a><br />";
                }
    
                if (mensaje) {
                    frappe.msgprint({
                        title: 'Casos en los que ha sido mencionado: ',
                        indicador: 'red',
                        message: "Tiene pendiente revisar los siguientes casos en los que ha sido mencionado y están abiertos:<br />" +
                        "<br />" + mensaje
                    });
                } else {
                    frappe.msgprint("No tiene casos en los que ha sido mencionado y estén abiertos.");
                }
    
            });
        };
    }

    frappe.wait_while_exists = function (selector, callback) {
        let ele = $(selector);

        if (ele.length == 0) {
            setTimeout(function () {
                frappe.wait_while_exists(selector, callback);
            }, 100);
        } else {
            callback();
        }

    };

});