$(document).ready(function () {
    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    frappe.ui.form.handlers_refresh.push(function (frm, doctype, name) {
        let ca = frm.fields_dict['contrato_arrendamiento'];

        if (ca) {
            let wrapper = ca.$wrapper;
            let cav = frm.doc.contrato_arrendamiento;

            wrapper.find('.boton-ca').remove();

            if (!cav) {
                return;
            }

            let div = $(`
                <div class='boton-ca like-disabled-input'>
                    <i class='fa fa-eye'></i>
                    <a href='javascript:;'>
                        VER CONTRATO
                    </a>
                </div>`);
            let a = div.find('a');

            wrapper.find('.control-input-wrapper .control-value').after(div);

            a.click(() => {
                frappe
                    .call({
                        method: 'frappe.client.get',
                        args: {
                            doctype: 'Contratos de Arrendamiento',
                            name: cav
                        }
                    })
                    .then((rp) => {
                        let doc = rp.message;

                        let table = `
                    <b>Inmueble</b>
                    <ul>
                        <li>Dirección: ${doc.direccion || ''}</li>
                        <li>Barrio: ${doc.barrio_inmueble || ''}</li>
                        <li>Tipo de Predio: ${doc.tipo_predio || ''}</li>
                        <li>Destinación: ${doc.destinacion || ''}</li>
                        <li>Fecha de Inicio: ${doc.fecha_inicio || ''}</li>
                        <li>Fecha Final: ${doc.fecha_final || ''}</li>
                        <li>Valor Canon: ${
                            $(
                                frappe.format(doc.valor_canon, {
                                    fieldtype: 'Currency'
                                })
                            ).text() || ''
                        }</li>
                        <li>Valor Canon Vigente: ${
                            $(
                                frappe.format(doc.valor_canon_vigente, {
                                    fieldtype: 'Currency'
                                })
                            ).text() || ''
                        }</li>
                    </ul>
                    <b>Cliente Propietario</b>
                    <ul>
                        <li>Identificación Cliente Propietario: ${
                            doc.identificacion_cliente_propietario
                        }</li>
                        <li>Nombre Cliente Propietario: ${
                            doc.nombre_cliente_propietario
                        }</li>
                        <li>Teléfono Cliente Propietario: ${
                            doc.telefono_cliente_propietario || ''
                        }</li>
                        <li>Celular Cliente Propietario: ${
                            doc.celular_cliente_propietario || ''
                        }</li>
                        <li>Correo Cliente Propietario: ${
                            doc.correo_cliente_propietario || ''
                        }</li>
                    </ul>
                    <b>Cliente Arrendatario</b>
                    <ul>
                        <li>Identificación Cliente Arrendatario: ${
                            doc.identificacion_cliente_arrendatario
                        }</li>
                        <li>Nombre Cliente Arrendatario: ${
                            doc.nombre_cliente_arrendatario
                        }</li>
                        <li>Teléfono Cliente Arrendatario: ${
                            doc.telefono_cliente_arrendatario || ''
                        }</li>
                        <li>Celular Cliente Arrendatario: ${
                            doc.celular_cliente_arrendatario || ''
                        }</li>
                        <li>Correo Cliente Arrendatario: ${
                            doc.correo_cliente_arrendatario || ''
                        }</li>
                    </ul>
                    `;

                        frappe.msgprint(table, cav);
                    });
            });
        }
    });
});
