$(window).on("load", function () {
    let timeout = null;

    $("#body_div").on("DOMNodeInserted", function () {
        if (timeout) {
            clearTimeout(timeout);
        }

        setTimeout(function () {
            labelsVistaLista();
        }, 100);
    });

    $(document).on("page-change", function () {
        setTimeout(function () {
            labelsVistaLista();
        }, 1000);
    });
});

function labelsVistaLista() {
    var pages = $(".page-container");

    pages.each(function () {
        let pr = $(this).data("page-route");

        if ($(this).data("procesado")) {
            // return;
        }

        $(this).data("procesado", true);

        if (pr && pr.endsWith("List")) {
            $(this).find(".page-form .form-group").each(function () {
                if ($(this).find("label").length == 0) {
                    let title = $(this).data("original-title");

                    $(this).prepend("<label class='ellipsis' style='font-size: 10px;'>" + title + ":</label>");

                    let span = $(this).find("span.octicon");

                    if (span.length > 0) {
                        span.css("top", "30px");
                    }
                }
            });
        }
    });
}
