//Oculta los registros en el doctype Tareas que inicien con OCULTAR:

$(window).on("load", function () {
    todoOcultarFix_event();
});

$(window).bind('hashchange', function() {
    todoOcultarFix_event();
});

function todoOcultarFix_event() {
    setTimeout(function () {
        $("#page-List\\/ToDo\\/List .result").unbind("DOMSubtreeModified", todoOcultarFix);
        $("#page-List\\/ToDo\\/List .result").on("DOMSubtreeModified", todoOcultarFix);
        todoOcultarFix();
    }, 2000);
}

function todoOcultarFix() {
    setTimeout(function () {
        $("#page-List\\/ToDo\\/List .result .list-row-container").each(function () {
            if ($(this).text().indexOf("OCULTAR:") != -1) {
                $(this).hide();
            }
        });
    }, 200);
}