$(document).ready(function () {
    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    frappe.ui.form.handlers_refresh.push(function (frm, doctype, name) {
        frm.fields.forEach(field => {
            let df = field.df;
            let fn = (df.fieldname || '').toLowerCase();

            if (fn.indexOf('mail') > -1 || fn.indexOf('correo') > -1) {
                let wrapper = field.$wrapper;

                if (!wrapper) {
                    return;
                }

                wrapper.find(".send-email").remove();

                if (!frm.doc[df.fieldname] || frm.doc[df.fieldname].toString().indexOf("@") == -1) {
                    return;
                }

                let div = $(`
                    <div class='send-email like-disabled-input boton-email-fix'>
                        <i class='fa fa-envelope'></i>
                        <a href='javascript:;'>Redactar Correo: ${frm.doc[df.fieldname]}</a>
                    </div>`
                );

                div.find("a").click(function () {
                    new frappe.views.CommunicationComposer({
                        doc: frm.doc,
                        frm: frm,
                        recipients: frm.doc[df.fieldname]
                    });
                });

                wrapper.find(".control-input-wrapper .control-value").after(div);
            }
        });
    });
});
