
$(document).ready(function () {
    frappe.ui.form.on('Address', {
        refresh(frm) {
            if (frm.doc.region_etiqueta === undefined || frm.doc.region_etiqueta == "") {
                ocultar_mostrar_campo(frm, 'state', 1)
            }
            if (frm.doc.ciudad_etiqueta === undefined || frm.doc.ciudad_etiqueta == "") {
                ocultar_mostrar_campo(frm, 'city', 1)
            }

        },
        region_etiqueta(frm) {
            frm.set_df_property('state', 'hidden', 0);
            frm.set_query("ciudad_etiqueta", function () {
                return {
                    "filters": {
                        "id_region": frm.doc.id_region,
                    },
                };
            });
        },
        ciudad_etiqueta(frm) {
            frm.set_df_property('city', 'hidden', 0);
        },
        state(frm) {
            if (frm.doc.state === undefined || frm.doc.state == "") {
                ocultar_mostrar_campo(frm, 'state', 1)
                cambiar_color_label('Seleccione un departamento', 'red')
            }
        },
        city(frm) {
            cambiar_color_label('Seleccione una ciudad', 'red')
            if (frm.doc.city === undefined || frm.doc.city == "") {
                ocultar_mostrar_campo(frm, 'city', 1)
            }
        }
    });
});

function ocultar_mostrar_campo(frm, campo, accion) {
    frm.set_df_property(campo, 'hidden', accion);
}

function cambiar_color_label(label, color) {
    var x = document.getElementsByClassName("control-label");
    for (let i = 0; i < x.length; i++) {
        if (x[i].innerHTML === label) {
            x[i].style.color = color
        }
    }
}