$(document).ready(function () {
    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    frappe.ui.form.handlers_refresh.push(function (frm, doctype, name) {
        let wrapper = $(frm.$wrapper);
        let fp = wrapper.find(".form-page");

        fp.find(".plugin-nc").remove();
        
        if (frm.doc.__islocal) {
            return;
        }

        if (frm.fields_dict['contrato_arrendamiento'] == null) {
            return null;
        }

        frm.add_custom_button("Crear No Conforme Interno", function () {
            if (!frm.doc.contrato_arrendamiento) {
                frappe.msgprint("Error, este documento no tiene asignado un contrato de arrrendamiento");
                return;
            }

            var d = new frappe.ui.Dialog({
                'title': 'Crear No Conforme Interno',
                'fields': [
                    {'fieldname': 'fecha', 'fieldtype': 'Date', 'label': 'Fecha'},
                    {'fieldname': 'responsable', 'fieldtype': 'Link', 'label': 'Responsable', 'options': 'User'},
                    {'fieldname': 'prioridad', 'fieldtype': 'Link', 'label': 'Prioridad', 'options': 'Issue Priority'},
                    {'fieldname': 'area', 'fieldtype': 'Select', 'label': 'Proceso', 'options': [
                        '',
                        'Administración de Inmuebles',
                        'Administrativo Financiero',
                        'Comercial',
                        'Desarrollo Tecnológico',
                        'Estratégico',
                        'Entrega de Inmuebles',
                        'Gestión de Calidad',
                        'Jurídico',
                        'Propiedad Horizontal',
                        'Reparaciones',
                        'Servicio al Cliente',
                        'SGSST'
                    ]},
                    {'fieldname': 'tipologia', 'fieldtype': 'Select', 'label': 'Tipología', 'options': [
                        '',
                        'Acuerdo De Reparaciones Entre Propietario Y Arrendatario',
                        'Atención De Requerimientos De Clientes',
                        'Atención De Requerimientos De Clientes',
                        'Carga De Cupones En Página',
                        'Descuentos / Beneficios Dados Por Comercial',
                        'Entrega De Inmuebles Para Promoción',
                        'Envío De Certificado De Retención',
                        'Error En Creación De Terceros',
                        'Error Identificación En Recibo De Caja',
                        'Funcionamiento Simi',
                        'Inconformidad relacionada con el ERP',
                        'Modificación De Contratos',
                        'No Hay Inventario Inicial',
                        'No Reporte De Pago De Administración Por El Propietario',
                        'Notificación De Novedades Pago De Canon Propietario',
                        'Procedimiento Entrega De Inmuebles',
                        'Programación Pago De Cupones',
                        'Seguimiento Reparaciones',
                        'Verificación De Facturas',
                        'Otro'
                    ]},
                    {'fieldname': 'titulo', 'fieldtype': 'Data', 'label': 'Título'},
                    {'fieldname': 'clacificacion', 'fieldtype': 'Select', 'label': 'Clasificación', 'options': [
                        '',
                        'Salida no conforme',
                        'No conformidad'
                    ]},
                    {'fieldname': 'comentario', 'fieldtype': 'Text Editor', 'label': 'Comentario'},
                ],
                primary_action: function() {
                    let dict = d.get_values();

                    if (!dict['fecha'] || !dict['responsable'] || !dict['prioridad'] || !dict['area'] ||
                             !dict['tipologia'] || !dict['titulo'] || !dict['clacificacion'] || !dict['comentario']) {
                        frappe.show_alert("Por favor, llene todos los campos");
                        return;
                    }

                    d.hide();

                    let contrato = null;

                    frappe.call({
                        method: 'frappe.client.get',
                        args: {
                            doctype: 'Contratos de Arrendamiento',
                            name: frm.doc.contrato_arrendamiento
                        },
                        async: false,
                        callback: function (rp) {
                            contrato = rp.message;
                        }
                    });

                    if (!contrato) {
                        frappe.msgprint("Error, este contrato no fue encontrado");
                        return;
                    }

                    dict['doctype'] = 'No Conforme Interno';
                    dict['estado'] = 'Open';
                    dict['propuesto_por'] = frappe.session.user;
                    dict['doctype_caso'] = frm.doc.doctype;
                    dict['id_caso'] = frm.doc.name;
                    dict['id_simi'] = contrato.id_simi;

                    frappe.call({
                        "method": "frappe.client.insert",
                        args: {
                            doc: dict
                        },
                        callback: function () {
                            frappe.msgprint("No Conforme Interno creado correctamente");
                            frm.refresh();
                        }
                    });
                }
            });

            d.show();
        }, null, "info");
        
        frappe.call({
            method: "inmobiliaria.rpc.no_conforme_internos_doctype",
            args: {
                doctype: frm.doc.doctype,
                name: frm.doc.name
            },
            callback: (r) => {
                /*r.message.forEach(i => {
                    pc.append(`<option value="${i.name}">${i.subject}</option>`);
                });*/
            },
            async: false
        }).then(ret => {
            if (ret.message.length > 0) {
                let section = $(`
                <div class="row form-section visible-section shaded-section plugin-nc">
                    <div class="col-sm-12">
                        <h6 class="form-section-heading uppercase">NO CONFORMES INTERNOS ASOCIADOS AL CASO</h6>
                    </div>
                    <div class="section-body">
                        <div class="form-column col-sm-12 col-xs-12">
                            <form>
                                <table class='table table-striped table-condensed'>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th>Propuesta Por</th>
                                    <th>Responsable</th>
                                    <th>Área</th>
                                    <th>Tipología</th>
                                    <th>Título</th>
                                    <th class='none'>Clasificación</th>
                                    <th class='none'>Comentario</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
                `);

                fp.prepend(section);

                let table = section.find("table");
                let tbody = section.find("tbody");

                tbody.html("");

                ret.message.forEach(i => {
                    let tr = $(`
                    <tr>
                        <td><a href='#Form/No Conforme Interno/${i.name}'>${i.name}</a></td>
                        <td>${i.fecha}</td>
                        <td>${__(i.estado)}</td>
                        <td>${__(i.propuesto_por)}</td>
                        <td>${__(i.responsable)}</td>
                        <td>${__(i.area)}</td>
                        <td>${__(i.tipologia)}</td>
                        <td>${__(i.titulo)}</td>
                        <td>${__(i.clacificacion)}</td>
                        <td class='text-ellipsis'>${i.comentario}</td>
                    </tr>
                    `);
                    
                    tbody.append(tr);
                });

                let datatable = table.DataTable({
                    "language": {
                        "sProcessing":     "Procesando...",
                        "sLengthMenu":     "Mostrar _MENU_ registros",
                        "sZeroRecords":    "No se encontraron resultados",
                        "sEmptyTable":     "Ningún dato disponible en esta tabla =(",
                        "sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
                        "sInfoPostFix":    "",
                        "sSearch":         "Buscar:",
                        "sUrl":            "",
                        "sInfoThousands":  ",",
                        "sLoadingRecords": "Cargando...",
                        "oPaginate": {
                            "sFirst":    "Primero",
                            "sLast":     "Último",
                            "sNext":     "Siguiente",
                            "sPrevious": "Anterior"
                        },
                        "oAria": {
                            "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
                            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                        },
                        "buttons": {
                            "copy": "Copiar",
                            "colvis": "Visibilidad"
                        }
                    },
                    "responsive": true
                });
            }
        });

    });
});