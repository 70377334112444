$(document).ready(function () {
    let notificaciones_diarias = function () {
        let date = new Date().toISOString().split("T")[0];
        let key = `tareas-${date}`;

        if (sessionStorage.getItem(key) || !document.location.href.includes("/desk")) {
            return;
        }

        sessionStorage.setItem(key, true);
        frappe.db.get_list("ToDo", {
            fields: ["*"],
            filters: {
                owner: frappe.session.user,
                date: date,
            },
        }).then(records => {
            if (!records || records.length == 0) {
                return;
            }

            let html = "<h3>Tareas que se vencen hoy</h3><br />";
            html += "<ul>";
            records.forEach(record => {
                html += `<li>
                <a href="/desk#Form/ToDo/${record.name}" target="_blank">
                    ${record.name}<br />
                    <small>${record.description}</small>
                </a>
                </li>`;
            });
            html += "</ul>";
            frappe.msgprint({
                title: "Tareas que se vencen hoy",
                indicator: "blue",
                message: html,
            });
        });
    };

    notificaciones_diarias();

    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    frappe.ui.form.handlers_refresh.push(function (frm, doctype, name) {
        let wrapper = $(frm.$wrapper);
        let fp = wrapper.find(".form-page");
        let datatable = null;

        fp.find(".plugin-tareas").remove();

        if (frm.doc.__islocal) {
            return;
        }

        frm.add_custom_button("Crear Tarea", function () {
            var d = new frappe.ui.Dialog({
                'title': 'Crear Tarea',
                'fields': [{
                        'fieldname': 'fecha',
                        'fieldtype': 'Date',
                        'label': 'Fecha de Vencimiento'
                    },
                    {
                        'fieldname': 'prioridad',
                        'fieldtype': 'Select',
                        'label': 'Prioridad',
                        'options': ['Low', 'Medium', 'High']
                    },
                    {
                        'fieldname': 'asignado_a',
                        'fieldtype': 'Link',
                        'label': 'Asignado a',
                        'options': 'User',
                        'default': frappe.session.user
                    },
                    {
                        'fieldname': 'descripcion',
                        'fieldtype': 'Small Text',
                        'label': 'Descripción'
                    },
                ],
                primary_action: function () {
                    let dict = d.get_values();

                    if (!dict['fecha'] || !dict['prioridad'] || !dict['asignado_a'] || !dict['descripcion']) {
                        frappe.show_alert("Por favor, llene todos los campos");
                        return;
                    }

                    d.hide();

                    let contrato = null;

                    frappe.call({
                        method: 'frappe.client.get',
                        args: {
                            doctype: 'Contratos de Arrendamiento',
                            name: frm.doc.contrato_arrendamiento
                        },
                        async: false,
                        callback: function (rp) {
                            contrato = rp.message;
                        }
                    });

                    if (!contrato) {
                        frappe.msgprint("Error, este contrato no fue encontrado");
                        return;
                    }

                    frappe.call({
                        "method": "frappe.client.insert",
                        args: {
                            doc: {
                                doctype: 'ToDo',
                                date: dict['fecha'],
                                priority: dict['prioridad'],
                                owner: dict['asignado_a'],
                                description: dict['descripcion'],
                                id_simi: contrato.id_simi,
                                reference_type: frm.doc.doctype,
                                reference_name: frm.doc.name,
                                assigned_by: frappe.session.user
                            }
                        },
                        callback: function (data) {
                            frappe.msgprint("Tarea creada correctamente");
                            frm.refresh();
                        }
                    });
                },
            });
            d.show();
        }, null, "info");

        frappe.call({
            method: "inmobiliaria.rpc.tareas_doctype",
            args: {
                doctype: frm.doc.doctype,
                name: frm.doc.name,
            },
            callback: (r) => {
                /*r.message.forEach(i => {
                    pc.append(`<option value="${i.name}">${i.subject}</option>`);
                });*/
            },
            async: false
        }).then(ret => {
            if (ret.message.length > 0) {
                let section = $(`
                <div class="row form-section visible-section shaded-section plugin-tareas">
                    <div class="col-sm-12">
                        <h6 class="form-section-heading uppercase">TAREAS ASOCIADAS AL CASO</h6>
                    </div>
                    <div class="section-body">
                        <div class="form-column col-sm-12 col-xs-12">
                            <form>
                                <table class='table table-striped table-condensed'>
                                <thead>
                                <tr>
                                <th>ID</th>
                                <th>Estado</th>
                                <th>Prioridad</th>
                                <th>Fecha de Vencimiento</th>
                                <th>Responsable</th>
                                <th class='none'>Descripción</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
                `);

                fp.prepend(section);

                let table = section.find("table");
                let tbody = section.find("tbody");

                tbody.html("");

                ret.message.forEach(i => {
                    let tr = $(`
                    <tr>
                        <td><a href='#Form/ToDo/${i.name}'>${i.name}</a></td>
                        <td>${__(i.status)}</td>
                        <td>${__(i.priority)}</td>
                        <td>${i.date || ''}</td>
                        <td>${i.owner}</td>
                        <td class='text-ellipsis'>${i.description}</td>
                    </tr>
                    `);

                    tbody.append(tr);
                });

                datatable = table.DataTable({
                    "language": {
                        "sProcessing": "Procesando...",
                        "sLengthMenu": "Mostrar _MENU_ registros",
                        "sZeroRecords": "No se encontraron resultados",
                        "sEmptyTable": "Ningún dato disponible en esta tabla =(",
                        "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
                        "sInfoPostFix": "",
                        "sSearch": "Buscar:",
                        "sUrl": "",
                        "sInfoThousands": ",",
                        "sLoadingRecords": "Cargando...",
                        "oPaginate": {
                            "sFirst": "Primero",
                            "sLast": "Último",
                            "sNext": "Siguiente",
                            "sPrevious": "Anterior",
                        },
                        "oAria": {
                            "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                        },
                        "buttons": {
                            "copy": "Copiar",
                            "colvis": "Visibilidad",
                        },
                    },
                    "responsive": true,
                });
            }
        });
    });
});