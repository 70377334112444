$(document).ready(function () {
    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    frappe.ui.form.handlers_refresh.push(function (frm, doctype, name) {
        let wrapper = $(frm.$wrapper);
        let fp = wrapper.find(".form-page");
        let datatable = null;

        // fp.find(".plugin-tareas").remove();
        
        if (frm.doc.__islocal) {
            return;
        }

        //Solo mostrará el botón si este documento tiene el campo contrato_arrendamiento
        if (!frm.fields_dict['contrato_arrendamiento']) {
            return;
        }

        frm.add_custom_button("Crear Reclamo", function () {
            var d = new frappe.ui.Dialog({
                'title': 'Crear Reclamo',
                'fields': [
                    {'fieldname': 'prioridad', 'fieldtype': 'Link', 'label': 'Prioridad', 'options': 'Issue Priority'},
                    {'fieldname': 'responsable', 'fieldtype': 'Link', 'label': 'Responsable', 'options': 'User', 'default': frappe.session.user},
                    {'fieldname': 'descripcion', 'fieldtype': 'Small Text', 'label': 'Descripción'},
                ],
                primary_action: function() {
                    let dict = d.get_values();

                    if (!dict['prioridad'] || !dict['responsable'] || !dict['descripcion']) {
                        frappe.show_alert("Por favor, llene todos los campos");
                        return;
                    }

                    d.hide();

                    let cs = null;

                    if (frm.doc.centro_de_servicio) {
                        frappe.call({
                            method: "frappe.client.get",
                            async: false,
                            args: {
                                doctype: "Centro de Servicio",
                                name: frm.doc.centro_de_servicio
                            }
                        }).then(rp => {
                            cs = rp.message;
                        });
                    } else if (frm.doc.doctype == 'Centro de Servicio') {
                        cs = frm.doc;
                    }

                    frappe.call({
                        "method": "frappe.client.insert",
                        args: {
                            doc: {
                                doctype: 'Reclamo',
                                status: 'Open',
                                prioridad: dict['prioridad'],
                                responsable: dict['responsable'],
                                tipo_de_solicitud: (cs ? cs.tipo_de_solicitud : ''),
                                descripcion_solicitud: dict['descripcion'],
                                contrato_arrendamiento: frm.doc.contrato_arrendamiento,
                                doctype_referenciado: frm.doc.doctype,
                                documento_referenciado: frm.doc.name,
                                centro_de_servicio: cs.name
                            }
                        },
                        callback: function (data) {
                            frappe.msgprint("Reclamo creado correctamente");
                            frm.refresh();
                        }
                    });
                }
            });

            d.show();
        }, null, "info");

        frappe.call({
            method: "inmobiliaria.rpc.reclamos_doctype",
            args: {
                doctype: frm.doc.doctype,
                name: frm.doc.name
            },
            async: false
        }).then(ret => {
            if (ret.message.length > 0) {
                let section = $(`
                <div class="row form-section visible-section shaded-section plugin-reclamos">
                    <div class="col-sm-12">
                        <h6 class="form-section-heading uppercase">RECLAMOS ASOCIADOS AL CASO</h6>
                    </div>
                    <div class="section-body">
                        <div class="form-column col-sm-12 col-xs-12">
                            <form>
                                <table class='table table-striped table-condensed'>
                                <thead>
                                <tr>
                                <th>ID</th>
                                <th>Estado</th>
                                <th>Prioridad</th>
                                <th>Responsable</th>
                                <th class='none'>Descripción</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
                `);

                fp.prepend(section);

                let table = section.find("table");
                let tbody = section.find("tbody");

                tbody.html("");

                ret.message.forEach(i => {
                    let tr = $(`
                    <tr>
                        <td><a target='_blank' href='#Form/Reclamo/${i.name}'>${i.name}</a></td>
                        <td>${__(i.status)}</td>
                        <td>${__(i.prioridad)}</td>
                        <td>${i.responsable}</td>
                        <td class='text-ellipsis'>${i.descripcion_solicitud}</td>
                    </tr>
                    `);
                    
                    tbody.append(tr);
                });

                datatable = table.DataTable({
                    "language": {
                        "sProcessing":     "Procesando...",
                        "sLengthMenu":     "Mostrar _MENU_ registros",
                        "sZeroRecords":    "No se encontraron resultados",
                        "sEmptyTable":     "Ningún dato disponible en esta tabla =(",
                        "sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
                        "sInfoPostFix":    "",
                        "sSearch":         "Buscar:",
                        "sUrl":            "",
                        "sInfoThousands":  ",",
                        "sLoadingRecords": "Cargando...",
                        "oPaginate": {
                            "sFirst":    "Primero",
                            "sLast":     "Último",
                            "sNext":     "Siguiente",
                            "sPrevious": "Anterior"
                        },
                        "oAria": {
                            "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
                            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                        },
                        "buttons": {
                            "copy": "Copiar",
                            "colvis": "Visibilidad"
                        }
                    },
                    "responsive": true
                });
            }
        });
        
    });
});