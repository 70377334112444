$(document).ready(function() {
    if (!frappe.ui.form.handlers_refresh) {
        frappe.ui.form.handlers_refresh = [];
    }

    let elegir = function(type, val) {
        let vals = val.split(/,|\//).filter(t => t);

        if (vals.length == 1) {
            let vrp = vals[0].replace(/[^0-0]+/, '');
            let a = null;

            if (type == 'wp') {
                a = $(
                    `<form target="_blank" method="post" action="https://web.whatsapp.com/send?phone=57${vrp}"></form>`
                );
            } else {
                a = $(`<form method="post" action="tel:${vrp}"></form>`);
            }

            $('body').append(a);

            a.submit();
            a.remove();
            // a.click();
            // a.remove();

        } else if (vals.length > 1) {
            let msg = 'Por favor, seleccione uno de los números: <br />';

            vals.forEach(v => {
                let vrp = v.replace(/[^0-9]+/, '');

                if (type == 'wp') {
                    msg += `<a target='_blank' href='https://web.whatsapp.com/send?phone=57${vrp}'>Whatsapp: ${v}</a><br />`;
                } else {
                    msg += `<a href='tel:${vrp}'>Llamar: ${v}</a><br />`;
                }
            });

            frappe.msgprint(msg);
        }
    };

    frappe.ui.form.handlers_refresh.push(function(frm, doctype, name) {
        frm.fields.forEach(field => {
            let df = field.df;
            let fn = (df.fieldname || '').toLowerCase();

            if (
                fn.indexOf('telefono') > -1 ||
                fn.indexOf('celular') > -1 ||
                fn.indexOf('phone') > -1 ||
                fn.indexOf('teléfono') > -1
            ) {
                let wrapper = field.$wrapper;

                wrapper.find('.call').remove();

                if (!frm.doc[df.fieldname]) {
                    return;
                }

                let div = $(`<div class='call like-disabled-input'>
                        <i class='fa fa-phone'></i>
                        <a href='javascript:;'>Whatsapp: ${
                            frm.doc[df.fieldname]
                        }</a>
                    </div>`);

                let div2 = $(`<div class='call like-disabled-input'>
                        <i class='fa fa-mobile'></i>
                        <a href='javascript:;'>Llamar: ${
                            frm.doc[df.fieldname]
                        }</a>
                    </div>`);

                wrapper
                    .find('.control-input-wrapper .control-value')
                    .after(div, div2);

                div.click(function() {
                    elegir('wp', frm.doc[df.fieldname]);
                });

                div2.click(function() {
                    elegir('tel', frm.doc[df.fieldname]);
                });
            }
        });
    });
});
